<template>

  <section id="canales-list">

    <div class="row align-items-center my-3">
      <div class="col-12 col-lg-9">
        <h3 class="section-head-title ps-0 mb-3 mb-lg-0">
          Conoce los canales que has creado en tu Mural Digital.
        </h3>
      </div>
      <div class="col-12 col-lg-3 d-flex justify-content-end">
        <router-link 
          :to="{ name: 'CanalCorporativo-add-canales' }"
          class="btn btn-custom btn-custom-color-blue btn-shadow border-round-50">
          <font-awesome-icon icon="plus" class="pe-2"/>
          Crear canal
        </router-link>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-12 col-lg-4">
        <v-select
          class="selvue-custom"
          :options="GetEstadoCanalesFiltro"
          :reduce="estados => estados.id_estado"
          label="nombre_estado"
          placeholder="Filtrar por estado"
          :clearable="false"
          @input="filterByStatus"
          v-model="selectedEstadoCanales"
        ><span slot="no-options"></span>
        </v-select>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <CanalesTable
          :loading="loading"
          :canales="listCanales"
          @duplicateChannel="duplicateChannel"
          @playRequest="playRequest"
          @deleteChannel="deleteChannel"
        />
      </div>
    </div>

    <Question
      v-if="question_modal"
      :msg="question_modal_msg"
      :hideCancel="false"
      @cancel="cancelDelete"
      @accept="acceptDelete"
    />
  </section>

</template>
 
 <script>
import { mapState, mapActions } from "vuex";
import CanalesTable from "./CanalesTable";
import Question from "../Modales/Question.vue";
export default {
  components: {
    CanalesTable,
    Question,
  },
  data() {
    return {
      question_modal: false,
      question_modal_msg: "",
      selectedEstadoCanales: 5,
      deletingChannelId: 0,
      pagination: {
        actual_page: 1,
        page_count: 0,
        page_range: 3,
        click_handler: this.changePage,
        prev_text:
          '<div class="btn-prevnext"><i class="fas fa-chevron-left"></i></div>',
        next_text:
          '<div class="btn-prevnext"><i class="fas fa-chevron-right"></i></div>',
        container_class: "users-list__pagination-container",
        page_class: "pagination-item",
      },
      //FF
      loading: true
    };
  },
  created(){
    this.getCanales();
    this.getEstado();
    let channelLayoutLS = localStorage.getItem("channelLayout");
    if (channelLayoutLS !== null && channelLayoutLS !== undefined) {
      localStorage.removeItem("channelLayout");
    }
  },
  computed: {
    ...mapState("canalCorporativoModule", ["listCanales", "GetEstadoCanalesFiltro"]),
  },
  methods: {
  ...mapActions("canalCorporativoModule", ["GetListCanalesAction", "deleteCanal", "getEstadoCanales"]),
    
    async getCanales(){
      this.loading = true;
      const data_ = { id_estado: this.selectedEstadoCanales }
      await this.GetListCanalesAction(data_);
      this.loading = false;
      
    },
    async getEstado(){
      await this.getEstadoCanales();
    },

    async acceptDelete() {
      this.question_modal = false;
      this.loading = true;
      const resDelete = await this.deleteCanal({ id_canal: +this.deletingChannelId });
      if (!resDelete){
        this.loading = false;
        this.$toast.open({
          message: "Falla al intentar eliminar el canal seleccionado",
          type: "error",
          duration: 0,
          position: "top-right",
        });
      } else {
        if (resDelete.id_canal == null || resDelete.id_canal == 0){
            let mensaje_resultado = resDelete.nombre_canal == null ? "Falla el proceso de eliminación" : resDelete.nombre_canal;
            this.loading = false;
            this.$toast.open({
              message: mensaje_resultado,
              type: "error",
              duration: 6000,
              position: "top-right",
          });
        }
        else {
          this.loading = false;
          this.$toast.open({
            message: "Tu canal ha sido eliminado correctamente",
            type: "success",
            duration: 6000,
            position: "top-right",
          });
        }
      }
    },
    cancelDelete() {
      this.question_modal = false;
    },
    deleteChannel(id_channel) {
      this.question_modal = true;
      this.question_modal_msg =
        "Al confirmar esta acción, dejará de reproducirse el contenido, ¿Deseas continuar?";
      this.deletingChannelId = id_channel;
      
    },
    playRequest() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        this.$toast.open({
          message: "Tu pantalla se esta reproduciendo correctamente",
          type: "success",
          duration: 6000,
          position: "top-right",
        });
      }, 2000);
    },
    duplicateChannel() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        this.$toast.open({
          message: "Tu canal se ha duplicado correctamente",
          type: "success",
          duration: 3000,
          position: "top-right",
        });
      }, 2000);
    },
    async filterByStatus() {
      this.loading = true;
      await this.getCanales();
      this.loading = false;
    },
  },
};
</script>

